<template lang="pug">
v-card
  v-card-title
    v-btn(icon color="black" @click="$emit('back')")
      v-icon mdi-arrow-left
    span {{ title }}
  v-divider
  v-card-text
    v-row
      v-col.ptop-8.pbot-8(cols="6")
        fieldDatepicker(
          v-model="formData.date"
          label="Course Date"
          :min="currentDate"
          :hide-details="false"
          :error="formErrors.hasError('date')"
          :error-messages="formErrors.fields.date"
          @change="onFormChange()"
          @blur="onFormChange()"
        )

      v-col.ptop-8.pbot-8(cols="6")
        filter-autocomplete(
          v-model="formData.city"
          @input="$emit('update:city', $event)"
          :getItems="getCourseLocation"
          item-text="name"
          item-value="ID"
          label="Course Location"
          :error="formErrors.hasError('city')"
          :error-messages="formErrors.fields.city"
          @change="onFormChange"
        )
      v-col.ptop-8.pbot-8(cols="6")
        v-text-field(
          v-model="formData.maxStudents"
          type="number"
          min=0
          hide-details
          label="Max Students"
        )
      v-col.ptop-8.pbot-8(cols="6")
        v-text-field(
          v-model="formData.name"
          label='name'
          :error="formErrors.hasError('name')"
          :error-messages="formErrors.fields.name"
        )
      v-col.ptop-8.pbot-8(cols="12")
        v-row
          v-col.ptop-8.pbot-8(cols="6")
            p Reservation options
            div.reservation-options
              div.reservation-options__item(
                v-for="(item, index) in LICENSE_TYPES"
                :key="item.slug"
              )
                v-checkbox.mt-0(
                  :ref="'option-' + item.slug"
                  :input-value="isReservationOptionSelected(item.slug)"
                  :label="item.name"
                  hide-details
                  color="success"
                  @change="onReservationOptionChanged(item.slug, $event)"
                )
              p.reservation-options__error(
                v-if="formErrors.hasError('reservableLicenseTypes')"
              ) {{ formErrors.fields.reservableLicenseTypes }}
          v-col.ptop-8.pbot-8(cols="6")
            p Visibility options
            v-radio-group.mt-0(
              v-model="formData.isVisibleOnLanding"
              :mandatory="false"
              :error="formErrors.hasError('isVisibleOnLanding')"
              :error-messages="formErrors.fields.isVisibleOnLanding"
            )
              v-radio(label="Visible on landing" :value="true")
              v-radio(label="Invisible on landing" :value="false")
    slot
  v-divider
  v-card-actions
    slot(name="actions" :formData="formData")
      v-spacer
      v-btn(outlined width="80").btn-common Cancel
      v-btn(color="primary" width="80").btn-common Add
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import { convertToDefaultBackendFormat } from '@/util'
import {LICENSE_TYPES} from "@/util/const";
import featureFlagsMixin from '@/mixins/featureFlags.mixin';
import { CourseEditable } from '../../../../models/courseModel'

export default {
  mixins: [featureFlagsMixin],

  props: {
    formErrors: Object,
    editData: Object,
    courseLocation: [String, Number],
    showing: Boolean,
    title: String,
  },

  data: () => ({
    LICENSE_TYPES,
    formData: new CourseEditable(),
    currentDate: convertToDefaultBackendFormat(new Date(Date.now()))
  }),

  mounted() {
    if (this.courseLocation) {
      this.formData.city = parseInt(this.courseLocation);
      this.formData.rangeMode = true
      this.onFormChange();
    }
  },

  computed: {
    ...mapGetters({
      citiesWithAdditionalInfo: 'crmCourseCities/itemsWithAdditionalInfo'
    })
  },

  methods: {
    ...mapActions({
      getCourseLocation: 'crmCourseCities/list'
    }),
    loadDefaultFormData() {
      Object.assign(this.formData, this.editData)
    },
    isReservationOptionSelected(option) {
      return !!this.formData.reservableLicenseTypes && this.formData.reservableLicenseTypes.includes(option);
    },
    onReservationOptionChanged(slug, value) {
      if(!this.formData.reservableLicenseTypes) {
        this.formData.reservableLicenseTypes = [];
      }
      if (value) {
        this.formData.reservableLicenseTypes.push(slug);
      } else {
        this.formData.reservableLicenseTypes = this.formData.reservableLicenseTypes.filter(e => e !== slug);
      }
    },
    onFormChange() {
      const {city} = this.formData;
      let formObject = {
        date: this.formData.date,
        timeTo: '',
        timeFrom: '',
        location: '',
        rangeMode: this.formData.rangeMode,
        reservableLicenseTypes: this.formData.reservableLicenseTypes
      };
      if (this.formData.date) {
        formObject.dateFrom = this.formData.date
        formObject.dateTo = this.formData.date
      }
      if (this.citiesWithAdditionalInfo) {
        let { city_exams } = this.citiesWithAdditionalInfo.find(({ID}) => city === ID);
        if (city_exams.length) {
          const [ exam ] = city_exams;
          let timeFrom = exam.exam_time_from.split(':')
          let timeTo = exam.exam_time_to.split(':')
          timeFrom.pop()
          timeTo.pop()
          formObject = {
            ...formObject,
            timeTo: timeTo.join(':'),
            timeFrom: timeFrom.join(':'),
            location: exam.location,
          };
        }
      }
      this.$emit('update-form', formObject);
    }
  },

  components:{
    datepicker: () => import('@/app/admin/components/CrmDatepicker.vue'),
    timepicker: () => import('@/components/global/Timepicker.vue'),
    filterAutocomplete: () => import('@/components/global/FilterAutocomplete.vue'),
    fieldDatepicker: () => import('@/components/global/FieldDatepicker.vue')
  }
}
</script>

<style lang="scss" scoped>
.ptop-8 {
  padding-top: 8px;
}

.mt-0 {
  margin-top: 0;
}

.pbot-8 {
  padding-bottom: 8px;
}

::v-deep .v-text-field {
  margin-top: 0;
  padding-top: 6px;
}

.reservation-options {
  &__error {
    color: #ff5252 !important;
    font-size: 12px;
    margin-top: 4px;
    line-height: 1.1;
  }
}
</style>
