<template lang="pug">
  div.card(:class="{multiple, active, row}")
    div.card__title
      span
        slot
      div.extras(v-if="!row")
        count-item(
          tooltip="MIJN CBR"
          :classes="['count-item_mijn']"
          :data-test="DATA_TEST.MIJN_CBR"
          rounded
        )
          template(v-if="typeof item.mijnCbrQueueCount === 'number'") {{ item.mijnCbrQueueCount }}
          template(v-else) -

        count-item(
          tooltip="Slots"
          :classes="['count-item_slots']"
          :data-test="DATA_TEST.SLOTS"
          rounded
        ) {{ item.slotsCount }}
        v-tooltip(top v-if="false")
    div.card__body
      div.max-students(v-if="!hideMaxStudent")
        span {{ item.candidatesCount }}/
        template(v-if="typeof item.maxStudents === 'number'")
          CMMaxStudents(
            :maxStudents="item.maxStudents"
            :ID="item.ID"
            @updated="$emit('updated', $event)"
          )
        template(v-else) -
      div.counter(:class="{reservable: isReservable('auto')}")
        img(src="@/assets/img/ui/q-management/ic-auto.svg" width="12" height="12")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template {{ item.autoCounts.top_cbr_reserve_queue_count }}
          span Reserve queue
        span.counter__separator |
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template {{ item.autoCounts.top_cbr_exchange_queue_count }}
          span Exchange queue
      div.counter(:class="{reservable: isReservable('motor')}")
        img(src="@/assets/img/ui/q-management/ic-motor.svg" width="12" height="12")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template {{ item.motorCounts.top_cbr_reserve_queue_count }}
          span Reserve queue
        span.counter__separator |
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template {{ item.motorCounts.top_cbr_exchange_queue_count }}
          span Exchange queue
      div.counter(:class="{reservable: isReservable('scooter')}")
        img(src="@/assets/img/ui/q-management/ic-scooter.svg" width="12" height="12")
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template {{ item.scooterCounts.top_cbr_reserve_queue_count }}
          span Reserve queue
        span.counter__separator |
        v-tooltip(top)
          template(v-slot:activator="{ on }")
            span(v-on="on")
              template  {{ item.scooterCounts.top_cbr_exchange_queue_count }}
          span Exchange queue
      div.extras(v-if="row")
        count-item(
          tooltip="MIJN CBR"
          :classes="['count-item_mijn']"
          :data-test="DATA_TEST.MIJN_CBR"
          rounded
        )
          template(v-if="typeof item.mijnCbrQueueCount === 'number'") {{ item.mijnCbrQueueCount }}
          template(v-else) -

        count-item(
          tooltip="Slots"
          :classes="['count-item_slots']"
          :data-test="DATA_TEST.SLOTS"
          rounded
        ) {{ item.slotsCount }}
        v-tooltip(top v-if="false")
</template>

<script>
import CountItem from "../CMCourseCountItem.vue"
import {CM_DATA_TEST} from "@/app/admin/modules/course_managment/core/cm-const";
import CMMaxStudents from "@/app/admin/modules/course_managment/components/cm_course/CMMaxStudents.vue";

//highlight counts according to item.reservable_license_types

export default {
  components: {
    CMMaxStudents,
    CountItem,
  },

  props: {
    item: {
      Object,
      required: true,
    },
    hideMaxStudent: {
      type: Boolean,
      default: false,
    },
    multiple: Boolean,
    active: Boolean,
    row: Boolean,
  },

  data: () => ({
    DATA_TEST: CM_DATA_TEST.COUNTS
  }),

  methods: {
    isReservable(type) {
      return (this.item.reservable_license_types || []).includes(type)
    }
  }
}
</script>

<style lang="scss" scoped>
.card {
  margin-bottom: 4px;
  border-radius: 8px;
  border: 1px solid $default-bg-color;
  background: white;
  box-shadow: 0 4px 10px 0 rgba(0, 0, 0, 0.10);
  padding: 6px 8px;

  &.active, &:hover {
    border-color: $primary-color;

    &.multiple {
      border-color: #791571;
    }
  }

  &.row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-flow: nowrap;
  }

  &__title {
    display: flex;
    justify-content: space-between;
    align-items: flex-start;

    span {
      word-break: break-all;
      font-size: 11px;
    }
  }

  .extras {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    flex-shrink: 0;
    font-size: 11px;

    span {
      display: block;
      margin-left: 4px;
    }
  }

  &__body {
    display: flex;
    justify-content: space-between;
    align-items: center;
    font-size: 11px;
    margin: 12px -4px 0 -4px;

    .row & {
      margin: 0;
      min-width: 240px;
    }

    .max-students {
      padding: 0 2px;
      color: #4CAF50;
      border-right: 1px solid $border-color;
      text-align: center;
      width: 20%;
      flex-shrink: 0;

      .row & {
        width: 20%
      }
    }

    .counter {
      padding: 0 2px;
      text-align: center;
      width: calc(80% / 3);
      flex-shrink: 0;
      display: flex;
      justify-content: center;
      align-items: center;

      &:not(.reservable) {
        filter: grayscale(1);
        opacity: 0.45;
      }

      .row & {
        width: 20%
      }

      img {
        margin-right: 2px;
      }

      &__separator {
        padding: 0 2px;
      }
    }

    .extras {
      width: 20%;
    }
  }
}
</style>