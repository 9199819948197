var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"card",class:{multiple: _vm.multiple, active: _vm.active, row: _vm.row}},[_c('div',{staticClass:"card__title"},[_c('span',[_vm._t("default")],2),(!_vm.row)?_c('div',{staticClass:"extras"},[_c('count-item',{attrs:{"tooltip":"MIJN CBR","classes":['count-item_mijn'],"data-test":_vm.DATA_TEST.MIJN_CBR,"rounded":""}},[(typeof _vm.item.mijnCbrQueueCount === 'number')?[_vm._v(_vm._s(_vm.item.mijnCbrQueueCount))]:[_vm._v("-")]],2),_c('count-item',{attrs:{"tooltip":"Slots","classes":['count-item_slots'],"data-test":_vm.DATA_TEST.SLOTS,"rounded":""}},[_vm._v(_vm._s(_vm.item.slotsCount))]),(false)?_c('v-tooltip',{attrs:{"top":""}}):_vm._e()],1):_vm._e()]),_c('div',{staticClass:"card__body"},[(!_vm.hideMaxStudent)?_c('div',{staticClass:"max-students"},[_c('span',[_vm._v(_vm._s(_vm.item.candidatesCount)+"/")]),(typeof _vm.item.maxStudents === 'number')?[_c('CMMaxStudents',{attrs:{"maxStudents":_vm.item.maxStudents,"ID":_vm.item.ID},on:{"updated":function($event){return _vm.$emit('updated', $event)}}})]:[_vm._v("-")]],2):_vm._e(),_c('div',{staticClass:"counter",class:{reservable: _vm.isReservable('auto')}},[_c('img',{attrs:{"src":require("@/assets/img/ui/q-management/ic-auto.svg"),"width":"12","height":"12"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[[_vm._v(_vm._s(_vm.item.autoCounts.top_cbr_reserve_queue_count))]],2)]}}])},[_c('span',[_vm._v("Reserve queue")])]),_c('span',{staticClass:"counter__separator"},[_vm._v("|")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[[_vm._v(_vm._s(_vm.item.autoCounts.top_cbr_exchange_queue_count))]],2)]}}])},[_c('span',[_vm._v("Exchange queue")])])],1),_c('div',{staticClass:"counter",class:{reservable: _vm.isReservable('motor')}},[_c('img',{attrs:{"src":require("@/assets/img/ui/q-management/ic-motor.svg"),"width":"12","height":"12"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[[_vm._v(_vm._s(_vm.item.motorCounts.top_cbr_reserve_queue_count))]],2)]}}])},[_c('span',[_vm._v("Reserve queue")])]),_c('span',{staticClass:"counter__separator"},[_vm._v("|")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[[_vm._v(_vm._s(_vm.item.motorCounts.top_cbr_exchange_queue_count))]],2)]}}])},[_c('span',[_vm._v("Exchange queue")])])],1),_c('div',{staticClass:"counter",class:{reservable: _vm.isReservable('scooter')}},[_c('img',{attrs:{"src":require("@/assets/img/ui/q-management/ic-scooter.svg"),"width":"12","height":"12"}}),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[[_vm._v(_vm._s(_vm.item.scooterCounts.top_cbr_reserve_queue_count))]],2)]}}])},[_c('span',[_vm._v("Reserve queue")])]),_c('span',{staticClass:"counter__separator"},[_vm._v("|")]),_c('v-tooltip',{attrs:{"top":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('span',_vm._g({},on),[[_vm._v(" "+_vm._s(_vm.item.scooterCounts.top_cbr_exchange_queue_count))]],2)]}}])},[_c('span',[_vm._v("Exchange queue")])])],1),(_vm.row)?_c('div',{staticClass:"extras"},[_c('count-item',{attrs:{"tooltip":"MIJN CBR","classes":['count-item_mijn'],"data-test":_vm.DATA_TEST.MIJN_CBR,"rounded":""}},[(typeof _vm.item.mijnCbrQueueCount === 'number')?[_vm._v(_vm._s(_vm.item.mijnCbrQueueCount))]:[_vm._v("-")]],2),_c('count-item',{attrs:{"tooltip":"Slots","classes":['count-item_slots'],"data-test":_vm.DATA_TEST.SLOTS,"rounded":""}},[_vm._v(_vm._s(_vm.item.slotsCount))]),(false)?_c('v-tooltip',{attrs:{"top":""}}):_vm._e()],1):_vm._e()])])}
var staticRenderFns = []

export { render, staticRenderFns }