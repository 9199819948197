<template lang="pug">
maxStudentsProvider(
  v-slot="provider" 
  :maxStudents="maxStudents" :ID="ID" @updated="$emit('updated', {ID, maxStudents: $event})"
)
  v-dialog(
    max-width="400px" 
    :value="provider.showing" 
    @input="provider.updateShowing"
  )
    template(v-slot:activator="{on}")
      span(v-on="on").cm-slot 
        slot(name="title") {{ maxStudents }}
    v-card
      v-card-title 
        v-btn(icon color="black" @click="provider.toggle")
          v-icon mdi-arrow-left
        span Update max students
      v-divider
      v-card-text
        v-text-field(
          label="Count"
          type="number"
          min=0
          :value="provider.count"
          @input="provider.updateCount"
        )
      v-divider
      v-card-actions
        v-spacer
        v-btn(outlined width="80" @click="provider.toggle").btn-common Cancel
        v-btn(color="primary" width="80" @click="provider.send").btn-common Update
</template>

<script>
export default {
  props: {
    maxStudents: Number,
    ID: Number
  },
  
  components: {
    maxStudentsProvider: () => import('./CMMaxStudentsProvider.vue')
  }
}
</script>

<style lang="scss" scoped>
.cm-slot {

}
</style>